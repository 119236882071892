/**
 * UTility
 * @author
 * Chuka -- kalchuka@gmail.com
 * 
 * Major dependencies for this to work are the settings in config.js
 * 
 * 
 */
// Anything exported from this file is importable by other in-browser modules.
import axios from "axios";
import {apiEndpoints} from './config'
import "./global.css?modules=false";

export function publicApiFunction() {}

// let loggedInUserPromise = fetch('...').then(r => {
//     if (r.ok) {
//       return r.json()
//     } else {
//       throw Error(`Error getting user, server responded with HTTP ${r.status}`)
//     }
//   })
// let loggedInUserPromise = '89999'
export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
}

export function getUrl() {
  return "http://localhost:9000/";
}


  export function getLoggedInUser() {
    //const cookie = this.getCookie()
//       var token = document.cookie.
//       console.log(token);
// var base64Url = token.split('.')[1];
//     var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//     var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
//         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//     }).join(''));
//      this.tokendecoded = JSON.parse(jsonPayload);
//      return this.tokendecoded;
//console.log(parseJwt(decodedCookie));
//let ca = decodedCookie.split(';');
// for(let i = 0; i <ca.length; i++) {
//   let c = ca[i];
//   while (c.charAt(0) == ' ') {
//     c = c.substring(1);
//   }
//   console.log(c.indexOf(name));
//   if (c.indexOf(name) == 0) {
//     console.log(c.substring(name.length, c.length))
//   }
// }
// console.log("vv");
  }

  export function parseJwt (token) {
    console.log(token);
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

// export function ShowActivityLoader() {    
//   if (document.querySelector("#pleaseWaitDialog") == null) {
//       var modalLoading = '<div class="modal" id="pleaseWaitDialog" data-backdrop="static" data-keyboard="false" role="dialog" style="margin-top:40vh">\
//           <div class="modal-dialog" style="background-color:transparent;border:none">\
//               <div class="modal-content" style="background-color:transparent; text-align:center;border:none">\
//                   <div class="modal-body">\
//                       <div class="spinner-border" role="status" style="color:#fff">\
//   <span class="sr-only"></span>\
// </div>\
// <div style="color:#fff">Loading ...</div>\
//                   </div>\
//               </div>\
//           </div>\
//       </div>';
//       $(document.body).append(modalLoading);
//   }

//   $("#pleaseWaitDialog").modal("show");
// }

/**
* Hides "Please wait" overlay. See function showPleaseWait().
*/
export function hideActivityLoader() {
  $("#pleaseWaitDialog").modal("hide");
}



/**
   * check if this user is eligible to us this menu
   */
 export async function checkMenuEligibility(who,where) {    
 var url = "http://localhost:5003/v1/admin/verifyMenu";
 axios.post(url,{
  staffid:who,
  menuPath:where
 })
              .then(response => {
                if(response.data.code =='00'){
               return response
                }
              })
              .catch(e => {
                alert('Something went wrong')
              })

 }


  
/**
* EXPORT COMMON CONFIG SETTINGS
* 
* */

export function config() {    

  const config=
  { 
    schoolName:' Nnamdi Azikiwe University',//this has to be first letter capital so it will be easy to move to all capital or all lower
    app_name: 'MyUnizik',
    channelType: 'custom',
    schoolslug : 'UNIZIK',
    skolasuiteVersion: 'Skolasuite-pro v2.0'
  }
return config
}





/// GET ACTIVE SETTINGS
export function fetchactiveSets() {
  /***
 * 
 * HERE WE WANT TO 
 */
 //check if cookie is set
 let name = getCookie("-X_Zka");

 //if not set, send back to login
     if(!name){
   window.location.href =`${window.location.origin}/#/auth`;
     }
     /// decode the jst token
   var tokendecoded = parseJwt(name);
  var url = apiEndpoints().appUrl+'/activeset'
  const data = {staffId: tokendecoded.staffid}
  let loggedInUserPromise =  axios.post(url,{
    staffId: tokendecoded.staffid
}).then(r => {
  if (r) {
    console.log(r);
    return r
  } else {
    throw Error(`Error getting user, server responded with HTTP ${r.status}`)
  }
})
  return loggedInUserPromise;
}


/// GET ALL DEPARTMENTS
export function allDepartments() {
  var url = apiEndpoints().commonsUrl+'/get-department'
  let getallDepartments =  axios.post(url).then(r => {
  if (r) {
    console.log(r);
    return r
  } else {
    throw Error(`Error getting Departments, server responded with HTTP ${r.status}`)
  }
})
  return getallDepartments;
}

/// GET ALL DEPARTMENTAL COURSES
export function getAllDepartmentalCourses(dept='',level='') {
  const data ={
dept,level
  }
  var url = apiEndpoints().commonsUrl+'/get-departmental-courses'
  let getallDepartmentCourses =  axios.post(url,data).then(r => {
  if (r) {
    console.log(r);
    return r
  } else {
    throw Error(`Error getting Departmental Courses, server responded with HTTP ${r.status}`)
  }
})
  return getallDepartmentCourses;
}

/// GET ALL DEPARTMENTS
export function allFaculties() {
  var url = apiEndpoints().commonsUrl+'/get-faculty'
  let getallFaculties =  axios.post(url).then(r => {
  if (r) {
    console.log(r);
    return r
  } else {
    throw Error(`Error getting Departments, server responded with HTTP ${r.status}`)
  }
})
  return getallFaculties;
}


/// GET DEGREE
export function degree() {
  var url = apiEndpoints().commonsUrl+'/get-degree'
  let getDegree =  axios.post(url).then(r => {
  if (r) {
    console.log(r);
    return r
  } else {
    throw Error(`Error getting Departments, server responded with HTTP ${r.status}`)
  }
})
  return getDegree;
}


///// GET SESSIONS 
export function allSession() {
  var url = apiEndpoints().commonsUrl+'/get-session'
  let session =  axios.post(url).then(r => {
  if (r) {
    console.log(r);
    return r
  } else {
    throw Error(`Error getting Departments, server responded with HTTP ${r.status}`)
  }
})
  return session;
}


/// GET PROGRAMES
export function allProgrames() {
  var url = apiEndpoints().commonsUrl+'/get-program'
  let getallFaculties =  axios.post(url).then(r => {
  if (r) {
    console.log(r);
    return r
  } else {
    throw Error(`Error getting Departments, server responded with HTTP ${r.status}`)
  }
})
  return getallFaculties;
}


///GET ALL

/// GET ALL MENU DETAILS
export function menusAsigned(channel,
programExec,
staffId) {
  var url = apiEndpoints().appUrl+'/myMenus'
  let getmyMenus =  axios.post(url,{channel,
programExec,
staffId}).then(r => {
  if (r) {
    console.log(r);
    return r
  } else {
    throw Error(`Error getting Departments, server responded with HTTP ${r.status}`)
  }
})
  return getmyMenus;
}




export function allfees() {
  var url = apiEndpoints().commonsUrl+'/get-degree'
  let getDegree =  axios.post(url).then(r => {
  if (r) {
    console.log(r);
    return r
  } else {
    throw Error(`Error getting Departments, server responded with HTTP ${r.status}`)
  }
})
  return getDegree;
}




export function vueBeforeEach(){
  
}



/**
 * 
 * 
 *   axios interceptore
 * 
 * 
 */

export const secureAxios = axios.create({
  baseURL: '',
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

secureAxios.interceptors.request.use((config) => {
  const token = 'l';

  if (!token) {
    window.location.href = "/";
    return;
  }
  const AuthStr = "Bearer ".concat(getCookie('_-_i_-sz_DS_Ari-Max_mqats676gbk'));
  config.headers.Authorization = AuthStr;

  return config;
});


secureAxios.interceptors.response.use(
  (response) => {
    console.log(response);
    return response;
  },
  error => {
    if (error.response.status === 401) {
      window.location.href = '/';
    }
  }
);
/**
 * 
 * 
 *      SECURE AXIOS INTERCEPTOS ENDS HERE 
 * 
 * 
 */

/***
 * 
 * MODAL FOR UNIVERSAL ACTIVITY INDICATOR
 */
//  export function Popup() {
//   var myDialog = document.createElement("dialog");
//   document.body.appendChild(myDialog)
//   var text = document.createTextNode("This is a dialog window");
//   myDialog.appendChild(text);
//   myDialog.showModal();
// }

// Popup();